import { formField, formInput, formTextarea, formSelect, formCheckbox, formRadio, formFile } from '@twind/forms';
import { lineClamp } from '@twind/line-clamp';

/** @type {import('twind').Configuration} */
export default {
  plugins: {
    'form-checkbox': formCheckbox,
    'form-field': formField,
    'form-file': formFile,
    'form-input': formInput,
    'form-radio': formRadio,
    'form-select': formSelect,
    'form-textarea': formTextarea,
    'line-clamp': lineClamp,

    triangle: {
      width: 0,
      height: 0,
      border: '9px solid transparent',
      'border-top': '12px solid black',
      borderBottom: 0,
      cursor: 'pointer',
    },
    'triangle-lg': {
      width: 0,
      height: 0,
      border: '6px solid transparent',
      'border-top': '8px solid black',
      borderBottom: 0,
      cursor: 'pointer',
    },
    'triangle-sm': {
      width: 0,
      height: 0,
      border: '5px solid transparent',
      'border-top': '7px solid black',
      borderBottom: 0,
      cursor: 'pointer',
    },
    borderTopError: {
      'border-top-color': '#EB5757',
    },
  },
  theme: {
    fontFamily: {
      sans: "'Roboto', sans-serif",
      inter: "'Inter', sans-serif",
    },
    extend: {
      keyframes: {
        'order-text-animation': {
          '0%': { color: 'black' },
          '100%': { color: '#777777' },
        },
      },
      animation: {
        'order-text-animation': 'order-text-animation 1s ease-in-out',
      },
      boxShadow: {
        item: '5px 5px 0px #000000',
        'item-image': '2px 2px 0px #5b5b5b',
        'action-bar': '0px -2px 4px rgba(0, 0, 0, 0.25)',
        'main-input-lg': '3px 3px 0px #000000',
        'main-input-sm': '1px 1px 0px #000000',
        'neostrata-cart-box': '0 0 #000000',
        'neostrata-cart-img': '0 0 #000000',
        'ClassicBW-cart-box': '5px 5px 0px #000000',
        'ClassicBW-cart-img': '2px 2px 0px #5B5B5B',
        'CleanCalocurb-cart-box': '0 0 #000000',
        'CleanCalocurb-cart-img': '0 0 #000000',
      },
      screens: {
        standalone: { raw: '(display-mode:standalone)' },
        lg: { max: '1050px' },
        sm: { max: '600px' },
      },
      colors: {
        general: {
          disabled: '#D3D3D3',
          soldout: '#C8C8C8',
          white: '#FFFFFF'
        },
        Neostrata: { DEFAULT: '#001E60', primary: '#4F5458', secondary: '#EDEEEE' },
        ClassicBW: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        },
        CleanCalocurb: {
          DEFAULT: '#88CF42',
          primary: '#4F5458',
          secondary: '#EDEEEE',
        },
        Clean2Before: {
          DEFAULT: '#20204b',
          primary: '#4F5458',
          secondary: '#EDEEEE',
        },
        CleanBW: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        },
        NBC: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        },
        NUBU: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        },
        OneItem: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        },
        products: {
          DEFAULT: '#000000',
          primary: '#000000',
          secondary: '#000000',
        }
      },
    },
  },
};
