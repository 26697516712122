// @TODO: this file is duplicated in cart and dash
// setup a shared component library?

import { useEffect } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import * as rudder from 'rudder-sdk-js';

declare global {
  interface Window {
    rudderanalytics: typeof rudder;
  }

  var rudderanalytics: typeof rudder;
}

const { publicRuntimeConfig } = getConfig();
const { RUDDERSTACK_WRITE_KEY } = publicRuntimeConfig;

const snippet = `
  !function(){
    var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
    e.load("${RUDDERSTACK_WRITE_KEY}","https://creatorcarnizv.dataplane.rudderstack.com"),
    e.page()
  }();
`;

export default function RudderStackAnalytics() {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: snippet,
      }}
    />
  );
}

export function useRudderStackPageviewLogger() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (globalThis.rudderanalytics) {
        globalThis.rudderanalytics.page(url);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);
}

export function useRudderstack() {
  return globalThis.rudderanalytics;
}
