import withTwindApp from '@twind/next/app';
import { client } from 'lib/graphql';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'urql';
import twindConfig from '../twind.config';
import { Toaster } from 'react-hot-toast';
import { useRudderStackPageviewLogger } from 'analytics/RudderStack';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../styles/globals.css';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  useRudderStackPageviewLogger();

  const ComponentWithNoTSErrors = Component as any;

  return (
    <QueryClientProvider client={queryClient}>
      <Provider value={client}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Head>
        <Toaster />
        <ComponentWithNoTSErrors {...pageProps} />
      </Provider>
    </QueryClientProvider>
  );
}

export default withTwindApp(twindConfig, MyApp);
