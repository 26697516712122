import { createClient } from 'urql';

export const createSSRClient = (headers: HeadersInit) => {
  return createClient({
    url: `${process.env.API_ROOT}/api/graphql`,
    requestPolicy: 'cache-and-network',
    fetchOptions: () => {
      return {
        headers,
      };
    },
  });
};

export const ssrClient = createSSRClient({});

export const client = createClient({
  url: '/api/graphql',
});
